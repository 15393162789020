@import "variables.less";

.footer, .container,

.row-fluid {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer {
    height: 18em;
}

#footer-border {
    border-right: 0px;
    margin: 0em;
    padding: 1em 0em 0em 0em;
}

#copyright {
    margin: 0em;
    padding: 1em 0em 0em 0em;
}

#side-extension {
    display: none;
}

.mainmenu > li {
    max-width: 100%;
}

.span2 {
    padding-bottom: 1em;
}

.menus {
    padding-left: 0.5em;
    text-align: center;
}

#company .arrow-right {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid @lightGrey;
    border-bottom: none;
}

#company > ul {
    margin-left: 0em;
    margin-top: 0em;
    width: 100%;
}

body {padding: 0em;}
#sidebar-row {
    display: block;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left:0px;
}

#sidebar-span {
    display: block;
    text-align: center;
    width: 100%;
}

#main-row {
    background-color: #fff;
    display: block;
    margin-left: 0em;
    padding: 1em;
    width: 100%;
}

#logo {
    text-align: center;
}

#container-header {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
}

#status-code {
  margin: 20px 0;
}

